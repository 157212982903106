/* eslint-disable import/order */
import React, { useState } from 'react';
import { Navbar as BsNavbar, Nav, NavDropdown, Badge } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { routeNames } from 'routes';
import { HashLink } from 'react-router-hash-link';
import SiteLogo from 'assets/img/logo/site-logo.png';
import { IoMenu } from 'react-icons/io5';
import { AUDIT_LINK, WHITEPAPER_LINK } from 'config';
import clsx from 'clsx';
import { BuyTokensButton } from '../BuyTokensModal/BuyTokensButton';

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);

  const { hash, pathname } = useLocation();

  // console.log(pathname, hash);

  return (
    <div>
      <BsNavbar expand="xl" collapseOnSelect expanded={expanded}>
        <div
          className="container-fluid"
          style={{ maxWidth: 1200, marginTop: 25, marginBottom: 25 }}
        >
          <Link
            className="d-flex align-items-center navbar-brand mr-0"
            to={routeNames.home}
          >
            <img
              src={SiteLogo}
              width={220}
              alt="logo"
              style={{ width: 185, height: 35 }}
            />
          </Link>

          <BsNavbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          >
            <IoMenu />
          </BsNavbar.Toggle>

          <BsNavbar.Collapse
            id="responsive-navbar-nav"
            className="nav-menu-wrap"
          >
            <Nav className="nav-menu-box">
              <Link
                to={routeNames.home}
                className={clsx(
                  'custom-navbar-button',
                  pathname == '/' && !hash && 'active',
                )}
                onClick={() => setExpanded(false)}
              >
                Home
              </Link>

              <HashLink
                to={routeNames.home + '#token'}
                className={clsx(
                  'custom-navbar-button',
                  pathname == '/' && hash == '#token' && 'active',
                )}
                onClick={() => setExpanded(false)}
              >
                Token
              </HashLink>

              <HashLink
                to={routeNames.home + '#roadmap'}
                className={clsx(
                  'custom-navbar-button',
                  pathname == '/' && hash == '#roadmap' && 'active',
                )}
                onClick={() => setExpanded(false)}
              >
                Roadmap
              </HashLink>

              {/* <Link
                to={routeNames.vesting}
                className={clsx(
                  "custom-navbar-button",
                  pathname == "/vesting" && !hash && "active"
                )}
                onClick={() => setExpanded(false)}
              >
                VESTING
              </Link> */}

              <NavDropdown
                id="nav-dropdown-dark-example"
                title="Documents"
                style={{ color: 'white', marginLeft: '5px', marginTop: '1px' }}
              >
                <NavDropdown.Item
                  id="nav-dropdown-dark-example"
                  onClick={() => {
                    window.open(
                      'https://docs.google.com/spreadsheets/d/1Ieau-fTmZWP0wmZkYcqa77rBXTxGuCJXik5tD0BVwWY/',
                    );
                    setExpanded(false);
                  }}
                >
                  Token Metrics
                </NavDropdown.Item>

                {/* <NavDropdown.Item
                  id="nav-dropdown-dark-example"
                >
                  <a href={WHITEPAPER_LINK} className='custom-navbar-button' target='_blank' rel='noreferrer'>
                    Whitepaper
                  </a>
                </NavDropdown.Item> */}
                <div className="dropdown-item">
                  <a
                    href={WHITEPAPER_LINK}
                    className="custom-navbar-button"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setExpanded(false)}
                  >
                    Whitepaper
                  </a>
                </div>
              </NavDropdown>

              <NavDropdown
                id="nav-dropdown-dark-example"
                title="Audits"
                style={{ color: 'white', marginLeft: '5px', marginTop: '1px' }}
              >
                <NavDropdown.Item
                  id="nav-dropdown-dark-example"
                  onClick={() => {
                    window.open('https://xaudits.io/projects/OneDex');
                    setExpanded(false);
                  }}
                >
                  xAudits
                </NavDropdown.Item>
                <NavDropdown.Item
                  id="nav-dropdown-dark-example"
                  onClick={() => {
                    window.open(AUDIT_LINK);
                    setExpanded(false);
                  }}
                >
                  Arda
                </NavDropdown.Item>
              </NavDropdown>

              {/* <NavDropdown
                id="nav-dropdown-dark-example"
                title={
                  <>
                    ICO
                    <Badge bg='primary' style={{ color: 'white', marginLeft: '2px', marginTop:'-4px', position: 'absolute' }}>NEW</Badge>
                  </>
                }
                style={{ color: 'white', marginLeft: '5px', marginTop:'1px', marginRight: '10px' }}
              > */}
              {/* <NavDropdown.Item
                  id="nav-dropdown-dark-example"
                >
                  <Link
                    to={routeNames.whitelist} className='custom-navbar-button'
                    onClick={() => setExpanded(false)}
                  >
                    Get into Whitelist
                  </Link>
                </NavDropdown.Item> */}

              {/* <NavDropdown.Item
                  id="nav-dropdown-dark-example"
                >
                  <Link
                    to={routeNames.privatesale} className='custom-navbar-button'
                    onClick={() => setExpanded(false)}
                  >
                    Private Sale
                  </Link>
                </NavDropdown.Item> */}

              {/* <NavDropdown.Item
                  id="nav-dropdown-dark-example"
                >
                  <Link
                    to={routeNames.vesting} className='custom-navbar-button'
                    onClick={() => setExpanded(false)}
                  >
                    Vesting
                  </Link>
                </NavDropdown.Item>
              </NavDropdown> */}

              <a
                className="custom-navbar-button"
                href="https://onedex-docs.gitbook.io/onedex-docs/"
                target="_blank"
                rel="noreferrer"
                onClick={() => setExpanded(false)}
              >
                <span>Grow Your Project</span>
              </a>

              {/* <Link to={{ pathname: routeNames.comingsoon }} state={{ pastURL: location.pathname }} className='custom-navbar-button auth-button text-center'>
                <span>Launch App</span>
              </Link> */}
              <div className="d-flex flex-column align-items-center">
                <a
                  className="custom-navbar-button auth-button text-center"
                  href="https://swap.onedex.app"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => setExpanded(false)}
                >
                  <span>Launch App</span>
                </a>
                <BuyTokensButton />
              </div>
            </Nav>
          </BsNavbar.Collapse>
        </div>
      </BsNavbar>
    </div>
  );
};

export default Navbar;
